import EventEmitter from 'eventemitter3'
import EthAdapter from './eth'
import TronAdapter from './tron'

class Web3Connect extends EventEmitter {
  constructor({
    chainId,
    network,
    addressType, // ETH TRON
    wallet, // MetaMask TronLink
    rpcUrl,
    options = {},
  }) {
    super()
    this.adapter = null
    if (addressType === 'ETH') {
      this.adapter = new EthAdapter({ wallet, options, chainId, rpcUrl })
    } else if (addressType === 'TRON') {
      this.adapter = new TronAdapter({ wallet, options, chainId, rpcUrl })
    }
    this.chainId = chainId
    this.network = network
    this.addressType = addressType
    this.wallet = wallet
  }

  // 连接
  async connect() {
    if (this.adapter) {
      await this.adapter.connect()
      await this.subscribeProvider()
    }
  }

  // 切换网络
  async switchNetwork() {
    try {
      if (this.adapter) {
        await this.adapter.switchNetwork(...arguments)
      }
    } catch (e) {
      throw e
    }
  }

  handleConnect = (event) => {
    // 连接
    this.emit('connect', event)
  }

  handleDisconnect = (e) => {
    // 断开连接
    console.log('handleDisconnect', e)
    this.emit('disconnect', e)
  }

  handleAccountsChanged = async (accounts) => {
    // 断开登录
    if (accounts) {
      await this.adapter.getAccountInfo()
      this.emit(
        'accountsChanged',
        typeof accounts === 'string' ? [accounts] : accounts
      )
    }
  }

  handleChainChanged = async (event) => {
    // 链变化
    await this.adapter.getAccountInfo()
    this.emit('chainChanged', event?.chainId || event)
  }

  // 统一订阅事件
  async subscribeProvider() {
    console.log('subscribeProvider')
    this.removeSubscribeProvider()
    if (!this.adapter.provider.on) return
    this.adapter.provider.on('connect', this.handleConnect)
    this.adapter.provider.on('disconnect', this.handleDisconnect)
    this.adapter.provider.on('accountsChanged', this.handleAccountsChanged)
    this.adapter.provider.on('chainChanged', this.handleChainChanged)
    this.adapter.provider.on('stateChanged', this.handleChainChanged)
  }

  async removeSubscribeProvider() {
    if (this.adapter?.provider?._eventsCount > 0) {
      this.adapter.provider.removeListener('connect', this.handleConnect)
      this.adapter.provider.removeListener('disconnect', this.handleDisconnect)
      this.adapter.provider.removeListener(
        'accountsChanged',
        this.handleAccountsChanged
      )
      this.adapter.provider.removeListener(
        'chainChanged',
        this.handleChainChanged
      )
      this.adapter.provider.removeListener(
        'stateChanged',
        this.handleChainChanged
      )
    }
  }

  // 断开连接
  async disconnect() {
    await this.removeSubscribeProvider()
    await this.adapter?.disconnect()
  }

  // 获取账户信息
  async getAccountInfo() {
    return await this.adapter?.getAccountInfo()
  }

  // 签名
  async signMessage(message, account) {
    return await this.adapter?.signMessage(message, account)
  }

  // 获取代币小数位
  async getDecimals(tokenAddress) {
    return await this.adapter?.getDecimals(tokenAddress)
  }

  // 获取代币余额【合约地址，钱包地址】
  async getTokenBalance(tokenAddress, address) {
    return await this.adapter?.getTokenBalance(tokenAddress, address)
  }

  // 校验地址是否正确
  isValidAddress(address) {
    return this.adapter.isValidAddress(address)
  }

  //切换网络
  async switchNetwork() {
    return this.adapter?.switchNetwork(...arguments)
  }
}

export default Web3Connect
