import { computed } from 'vue'
import { useStore } from 'vuex'

const usePermission = () => {
  const store = useStore()

  const perList = computed(() => {
    const id = store.state._info.loginInfo?.roleId || ''
    switch (id) {
      // 超级管理员
      case 1:
        return [1, 2, 3]
      // 运营人员
      case 2:
        return [2]
      // 管理员
      case 3:
        return [2, 3]
    }
    return []
  })

  return {
    perList,
  }
}

export default usePermission
