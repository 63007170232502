import { tronApikey } from '@/constants/config'
import Web3Connect from '../adapter'

export async function getTokenBalance(
  { tokenAddress, contractAddress },
  current
) {
  try {
    const web3Connect = new Web3Connect({
      chainId: current.chainId,
      network: current.network,
      addressType: current.addressType,
      rpcUrl: current.rpcUrl,
      options: {
        tronApikey,
      },
    })
    return await web3Connect.getTokenBalance(tokenAddress, contractAddress)
  } catch (error) {
    throw error
  }
}

// 获取授权状态
export async function getAllowance(
  { tokenAddress, contractAddress, from },
  current
) {
  try {
    const web3Connect = new Web3Connect({
      chainId: current.chainId,
      network: current.network,
      addressType: current.addressType,
      rpcUrl: current.rpcUrl,
      options: {
        tronApikey,
      },
    })
    return await web3Connect?.adapter?.getAllowance?.(
      tokenAddress,
      from,
      contractAddress
    )
  } catch (error) {
    throw error
  }
}
