export const ChainId = {
  mainnet: 1,
  goerli: 5,
  arbitrum: 42161,
  arbitrumSepolia: 421614,
  optimism: 10,
  optimismSepolia: 11155420,
  sepolia: 11155111,
}

export const networkConfig = {
  [ChainId.mainnet]: {
    id: ChainId.mainnet,
    name: 'Ethereum',
    nativeCurrency: { name: 'Ether', symbol: 'ETH', decimals: 18 },
    rpcUrls: ['https://cloudflare-eth.com'],
    blockExplorers: ['https://etherscan.io'],
    isTestnet: false,
  },
  [ChainId.goerli]: {
    id: ChainId.goerli,
    name: 'Ethereum Görli',
    nativeCurrency: { name: 'Goerli Ether', symbol: 'ETH', decimals: 18 },
    rpcUrls: ['https://rpc.ankr.com/eth_goerli'],
    blockExplorers: ['https://goerli.etherscan.io'],
    isTestnet: true,
  },
  [ChainId.arbitrum]: {
    id: ChainId.arbitrum,
    name: 'Arbitrum One',
    nativeCurrency: { name: 'Ether', symbol: 'ETH', decimals: 18 },
    rpcUrls: ['https://arb1.arbitrum.io/rpc'],
    blockExplorers: ['https://arbiscan.io'],
    isTestnet: false,
  },
  [ChainId.arbitrumSepolia]: {
    id: ChainId.arbitrumSepolia,
    name: 'Arbitrum Sepolia',
    nativeCurrency: {
      name: 'Arbitrum Sepolia Ether',
      symbol: 'ETH',
      decimals: 18,
    },
    rpcUrls: ['https://sepolia-rollup.arbitrum.io/rpc'],
    blockExplorers: ['https://sepolia.arbiscan.io'],
    isTestnet: true,
  },
  [ChainId.optimism]: {
    id: ChainId.optimism,
    name: 'OP Mainnet',
    nativeCurrency: { name: 'Ether', symbol: 'ETH', decimals: 18 },
    rpcUrls: ['https://mainnet.optimism.io'],
    blockExplorers: ['https://optimistic.etherscan.io'],
    isTestnet: false,
  },
  [ChainId.optimismSepolia]: {
    id: ChainId.optimismSepolia,
    name: 'Optimism Sepolia',
    nativeCurrency: { name: 'Sepolia Ether', symbol: 'ETH', decimals: 18 },
    rpcUrls: ['https://sepolia.optimism.io'],
    blockExplorers: ['https://optimism-sepolia.blockscout.com'],
    isTestnet: true,
  },
  [ChainId.sepolia]: {
    id: ChainId.sepolia,
    name: 'Sepolia',
    nativeCurrency: { name: 'Sepolia Ether', symbol: 'SEP', decimals: 18 },
    rpcUrls: ['https://rpc.sepolia.org'],
    blockExplorers: ['https://sepolia.etherscan.io'],
    isTestnet: true,
  },
}
