// 页面尺寸实时计算，响应式布局使用
export default {
  data() {
    return {
      _bodyWidth: window.innerWidth,
      _bodyHeight: window.innerHeight,
    }
  },
  methods: {
    _update() {
      this._bodyWidth = window.innerWidth
      this._bodyHeight = window.innerHeight
    }
  },
  mounted() {
    window.addEventListener('resize', this._update)
  },
  beforeUnmount() {
    window.removeEventListener('resize', this._update)
  },
}