import Web3 from 'web3'
import baseABI from '../abis/erc20.json'
import {
  toNonExponential,
  toFixed,
  mul,
  divide,
  pow,
  lt,
} from '@/utils/calculation'
import { delay } from '@/utils/common'
import { APIResponse, RESPONSE_TYPE } from './response'
import { ConnectWalletError, BalanceNotEnough } from './errors'
import { networkConfig } from '@/constants/networkConfig'

export default class EthAdapter {
  constructor({ wallet, options, chainId, rpcUrl }) {
    this.wallet = wallet // 钱包类型
    this.chainId = chainId
    // 参数
    this.options = {
      ...options,
    }
    this.provider = null // 提供者
    this.accountInfo = null // 当前信息
    this.web3 = rpcUrl ? new Web3(rpcUrl) : null
  }

  // 创建provider
  async createProvider() {
    if (this._isMetaMask()) {
      // 多个插件情况
      if (window.ethereum.providers && window.ethereum.providers.length) {
        return Promise.resolve(
          window.ethereum.providers.find((item) => {
            return item.isMetaMask
          })
        )
      }
      return Promise.resolve(window.ethereum)
    }
  }

  // 连接
  async connect() {
    try {
      this.provider = await this.createProvider()
      if (!this.provider) return
      this.web3 = new Web3(this.provider)
      if (this._isMetaMask()) {
        await this.provider.request({
          method: 'eth_requestAccounts',
        })
      } else {
        await this.provider?.enable()
        console.log('this.provider', this.provider)
      }
      await this.switchNetwork(this.chainId)
    } catch (e) {
      console.log('eeeee', e)
      throw new ConnectWalletError(e?.message || e)
    }
  }

  async send(method, params) {
    try {
      return this.provider.request({
        method,
        params,
      })
    } catch (e) {
      console.log('send', e)
      throw e
    }
  }

  // 切换网络
  async switchNetwork(newChainId) {
    console.log('switchNetwork', newChainId)
    const chainId = `0x${Number(newChainId).toString(16)}`
    try {
      await this.send('wallet_switchEthereumChain', [{ chainId }])
      await this.getAccountInfo()
    } catch (switchError) {
      if (switchError.code === 4902) {
        try {
          const networkInfo = networkConfig[newChainId]
          await this.send('wallet_addEthereumChain', [
            {
              chainId,
              chainName: networkInfo.name,
              nativeCurrency: networkInfo.nativeCurrency,
              rpcUrls: networkInfo.rpcUrls,
              blockExplorerUrls: networkInfo.blockExplorers,
            },
          ])
        } catch (error) {
          console.log('send error', error)
          throw error
        }
      } else {
        throw switchError
      }
    }
  }

  // 获取用户信息
  async getAccountInfo() {
    try {
      const accounts = await this.web3.eth.getAccounts()
      const chainId = await this.web3.eth.getChainId()
      const data = {
        account: accounts[0] || '',
        chainId: chainId.toString(),
      }
      this.accountInfo = data
      return data
    } catch (err) {
      console.log('getAccountInfo', err)
      throw err
    }
  }

  // 断开连接
  async disconnect() {
    this.provider?.disconnect?.() // 插件无断开方法
    this.provider = null
    this.info = null
  }

  _isMetaMask() {
    // 是否MetaMask
    return this.wallet === 'MetaMask'
  }

  // 签名
  async signMessage(message, address) {
    try {
      let signature = null
      if (this._isMetaMask()) {
        signature = await this.web3.eth.personal.sign(message, address)
      } else {
        signature = await this.send('personal_sign', [message, address])
      }
      return signature
    } catch (e) {
      console.log('catch signMessage', e)
      throw e
    }
  }

  // 获取代币小数位
  async getDecimals(tokenAddress) {
    try {
      const contract = new this.web3.eth.Contract(baseABI, tokenAddress)
      const decimals = await contract.methods.decimals().call()
      return decimals
    } catch (error) {
      throw error
    }
  }

  // 获取代币余额
  async getTokenBalance(tokenAddress, address) {
    try {
      const contract = new this.web3.eth.Contract(baseABI, tokenAddress)
      const balance = await contract.methods.balanceOf(address).call()
      const decimals = await contract.methods.decimals().call()
      return toNonExponential(divide(balance, pow(10, decimals)))
    } catch (error) {
      throw error
    }
  }

  // 查询津贴
  async getAllowance(tokenAddress, from, address) {
    try {
      const contract = new this.web3.eth.Contract(baseABI, tokenAddress)
      const allowance = await contract.methods.allowance(from, address).call()
      return allowance
    } catch (error) {
      throw error
    }
  }

  // 申请津贴
  async sendApprove({ tokenAddress, from, address, value }) {
    try {
      console.log('sendApprove', arguments)
      const contract = new this.web3.eth.Contract(baseABI, tokenAddress)
      await contract.methods.approve(address, value).send({ from })
      return true
    } catch (error) {
      throw error
    }
  }

  // 直接转账
  async transfer({ address, tokenAddress, amount }, promiseEvent) {
    const apiResponse = new APIResponse('transfer')
    try {
      await delay(0)
      if (!this.web3 || !this.accountInfo || !promiseEvent.eventEmitter) {
        throw new Error('invalid provider')
      }
      // 付款地址
      const from = this.accountInfo.account
      const balance = await this.getTokenBalance(tokenAddress, from)
      console.log('balance', balance)
      if (lt(balance, amount)) {
        // 余额不足
        throw new BalanceNotEnough('balance not enough')
      }
      const { eventEmitter, resolve, reject } = promiseEvent
      eventEmitter.emit('status', apiResponse.ok(RESPONSE_TYPE.AUTH))

      const decimals = await this.getDecimals(tokenAddress)
      const value = mul(amount, pow(10, decimals))
      let txHash = null
      const contract = new this.web3.eth.Contract(baseABI, tokenAddress)
      contract.methods
        .transfer(address, value)
        .send({ from }, async (err, txId) => {
          if (err) {
            eventEmitter.emit('error', apiResponse.error(err))
            return reject(err)
          }
          if (txId) {
            txHash = txId
            eventEmitter.emit(
              'status',
              apiResponse.ok(RESPONSE_TYPE.TRANSACTION_CONFIRM),
              {
                txId,
              }
            )
            const res = await this.pollTxidStatus(txHash)
            if (res) {
              eventEmitter.emit(
                'status',
                apiResponse.ok(RESPONSE_TYPE.SUCCESS, {
                  txId,
                })
              )
            } else {
              eventEmitter.emit(
                'error',
                apiResponse.fail(
                  res === null ? RESPONSE_TYPE.TIMEOUT : RESPONSE_TYPE.FAILURE
                ),
                {
                  txId,
                }
              )
            }
            resolve(res ? txHash : false)
          } else {
            resolve(false)
          }
        })
        .catch((error) => {
          if (!txHash) {
            promiseEvent.eventEmitter.emit('error', apiResponse.error(error))
            promiseEvent.reject(error)
          }
        })
    } catch (error) {
      console.log('error', error)
      promiseEvent.eventEmitter.emit('error', apiResponse.error(error))
      promiseEvent.reject(error)
    }
  }

  // 查询gas费用
  async getEstimatedGasPrice() {
    try {
      const gasPrice = await this.web3.eth.getGasPrice()
      return gasPrice
    } catch (error) {
      throw error
    }
  }

  async getEstimatedGas(tokenAddress, from, address, value) {
    try {
      const contract = new this.web3.eth.Contract(baseABI, tokenAddress)
      const gas = await contract.methods
        .transfer(address, value)
        .estimateGas({ from })
      return toFixed(mul(gas, 2), 0)
    } catch (error) {
      throw error
    }
  }

  // 校验格式是否正确
  isValidAddress(address) {
    return Web3.utils.isAddress(address)
  }

  // 轮询txid方法
  async pollTxidStatus(txHash, maxAttempts = 50, waitInterval = 5000) {
    return new Promise(async (resolve, reject) => {
      await delay(200)
      let attempts = 0
      while (attempts < maxAttempts) {
        try {
          const txInfo = await this.web3.eth.getTransactionReceipt(txHash)
          if (txInfo) {
            resolve(txInfo.status)
            return
          }
          await delay(waitInterval)
          attempts++
        } catch (error) {
          // 忽略网络错误等
          await delay(waitInterval)
          attempts++
        }
      }
      resolve(null)
    })
  }
}
