// 自定义输出错误

// 余额错误
export class BalanceNotEnough extends Error {
  constructor(message) {
    super(message)
    this.name = 'BalanceNotEnough'
    this.errorCode = 4003
  }
}

export class BalanceMinimum extends Error {
  constructor(message, params) {
    super(message)
    this.name = 'BalanceMinimum'
    this.errorMessageParams = params
    this.errorCode = 4004
  }
}

// 钱包连接错误
export class ConnectWalletError extends Error {
  constructor(message) {
    super(message)
    this.name = 'ConnectWalletError'
    this.errorCode = 4002
  }
}
