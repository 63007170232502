import "core-js/modules/es.array.push.js";
import "core-js/modules/es.array.includes.js";
import "core-js/modules/es.string.includes.js";
import "core-js/modules/es.array.filter.js";
import "core-js/modules/es.object.to-string.js";
import "core-js/modules/es.function.name.js";
import "core-js/modules/es.array.find-index.js";
import { unref as _unref, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";
var _withScopeId = function _withScopeId(n) {
  return _pushScopeId("data-v-156fd7e3"), n = n(), _popScopeId(), n;
};
var _hoisted_1 = {
  class: "mb-6 flex flex-wrap"
};
var _hoisted_2 = ["onClick"];
var _hoisted_3 = {
  class: "ml-2"
};
import { watchEffect, ref, computed } from 'vue';
import { useRoute, useRouter } from 'vue-router';
import { useI18n } from 'vue-i18n';
import usePermission from '@/hooks/usePermission';
export default {
  __name: 'index',
  setup: function setup(__props) {
    var _useI18n = useI18n({
        useScope: 'global'
      }),
      t = _useI18n.t;
    var router = useRouter();
    var route = useRoute();

    // 权限列表
    var _usePermission = usePermission(),
      perList = _usePermission.perList;

    // 当前菜单
    var activeIndex = ref(-1);

    // 点击菜单
    var launchNav = function launchNav(path) {
      router.push(path);
    };
    var navList = computed(function () {
      var list = [{
        name: 'AddressPool',
        path: '/',
        label: t('route.addressPool'),
        icon: 'bm bm-wallet'
      },
      // {
      //   name: 'Payout',
      //   path: '/payout',
      //   label: t('route.payout'),
      //   icon: 'bm bm-usdt',
      // },
      {
        name: 'Withdrawal',
        path: '/withdrawal',
        label: t('route.withdrawal'),
        icon: 'bm bm-withdrawal-usdt'
      }, {
        name: 'Merchant',
        path: '/merchant',
        label: t('route.merchant'),
        icon: 'bm bm-user'
      }, {
        name: 'Authorization',
        path: '/authorization',
        label: t('route.authorization'),
        icon: 'bm bm-check-list'
      }, {
        name: 'Account',
        path: '/account',
        label: t('route.account'),
        icon: 'bm bm-users'
      }];
      // 是否是超级管理员
      return perList.value.includes(1) ? list : list.filter(function (item) {
        return item.name !== 'Authorization';
      });
    });

    // 识别当前菜单
    watchEffect(function () {
      var fullpath = route.fullPath || '/';
      activeIndex.value = navList.value.findIndex(function (el) {
        if (el.path === '/') {
          return fullpath === el.path || fullpath.indexOf('/address') !== -1;
        } else {
          return fullpath.indexOf(el.path) !== -1;
        }
      });
    });
    return function (_ctx, _cache) {
      return _openBlock(), _createElementBlock("nav", _hoisted_1, [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_unref(navList), function (item, index) {
        return _openBlock(), _createElementBlock("a", {
          key: index,
          class: _normalizeClass(['nav-item', {
            active: activeIndex.value === index
          }]),
          onClick: function onClick($event) {
            return launchNav(item.path);
          }
        }, [_createElementVNode("i", {
          class: _normalizeClass(['text-xl', item.icon])
        }, null, 2), _createElementVNode("span", _hoisted_3, _toDisplayString(item.label), 1)], 10, _hoisted_2);
      }), 128))]);
    };
  }
};